/*
	Listeners select payment method (for any of 3 product types):
 */
$(document).ready(function(){

	$('.payment-btns input[type="radio"]').change( function () {

		// toggle active option
		$('.payment-btns label').removeClass('active');
		$(this).parent().addClass('active');

		var selectedMethod = $(this).val();
		var laravelToken = $('input[name="_token"]').val();
		var productType = $('input[name="productType"]').val();
		var productId = $('input[name=productId]').val();
		
		if( selectedMethod == 'PrepaidSessions') 
		{
			prepaidMethodSetup(productId, laravelToken);
		} 
		else if( selectedMethod == 'CreditCard' ) 
		{
			creditCardMethodSetup(productType, productId, laravelToken);
		} 
		else if( selectedMethod == 'PayPal') 
		{
			payPalBTMethodSetup(productType, productId, laravelToken);
		}

	});

});

/*
	Listener CC Pay btn/spinner
 */
$('input#creditcard-submit').click(function(e) {
	$(this).addClass('hidden');
	$('div#cc_ajax-loader').removeClass('hidden');
});





/*
	Functions
 */

/**
 * Gets the URL to post the payent according to payment method and product type
 * @param  string productType      reservations|season_passes|prepaid_sessions
 * @param  int productId
 * @param  string paymentMethodKey [description]
 * @return string 	URL
 */
function mapPaymentURL(productType, productId, paymentMethodKey) {

	switch( productType ) {

		case 'reservations':
			var postURL = APP_URL + '/pay-reservation-' + paymentMethodKey + '/' + productId;
			break;

		case 'season_passes' :
			var postURL = APP_URL + '/pay-seasonpass-' + paymentMethodKey + '/' + productId;
			break;

		case 'prepaid_sessions' : 
			var postURL = APP_URL + '/pay-prepaidsessions-' + paymentMethodKey + '/' + productId;
			break;
	}

	return postURL;

}

/**
 * Setup Credit Card Method
 * @param  {[type]} productType  [description]
 * @param  {[type]} productId    [description]
 * @param  {[type]} laravelToken [description]
 * @return {[type]}              [description]
 */
function creditCardMethodSetup(productType, productId, laravelToken) {

	$('div#pay-with-prepaid-action').addClass('hidden');
	$('div#paypal-bt-container').addClass('hidden');
	$('div#creditcard-fields').removeClass('hidden');

	var BTclientToken = $('input[name=BTclientToken]').val();
	var postURL = mapPaymentURL(productType, productId, 'creditcard');

	braintree.setup(BTclientToken, "custom", {
		id : 'CreditCardForm',
		onReady : function () {
			console.log('onready');
		},
		onPaymentMethodReceived : function (paymentMethodNonceObj) { 	
			// Send the received Method Nonce back to the app server
			$.post( postURL,
				{ 
					'selectedMethod' : paymentMethodNonceObj.type, 
					'nonce'  : paymentMethodNonceObj.nonce,
					'_token' : laravelToken
				},
				function (res) {
					$('div#cc_ajax-loader').addClass('hidden');
					$('input#creditcard-submit').removeClass('hidden');
					if(res.result_key=='CC_ERRORS') {
						$divErrors = $('#cc_errors').html('');
						$.each(res.errors , function (i,el) {
							$divErrors.append( $('<p>').html(el) );
						})
					} else if(res.result_key=='OK') {
						$('.payment-btns').html( 
							$('<p class="alert alert-success">')
								.append('Credit Card Payment Approved <br>')
								.append(  $('<a>').attr('href', APP_URL+'/account/home').html('Back to my account') )
						);
					}
				},
				'json'
			);	
		}, 
		onError : function (errorObj) {
			//console.log(errorObj);
		},


	});
}


/**
 * Setup PayPal Method
 * @param  {[type]} productType  [description]
 * @param  {[type]} productId    [description]
 * @param  {[type]} laravelToken [description]
 * @return {[type]}              [description]
 */
function payPalBTMethodSetup(productType, productId, laravelToken) {

	$('div#creditcard-fields').addClass('hidden');
	$('div#pay-with-prepaid-action').addClass('hidden');
	$('div#paypal-bt-container').removeClass('hidden');

	var BTclientToken = $('input[name=BTclientToken]').val();
	var orderTotal = $('input[name=orderTotal]').val();
	var postURL = mapPaymentURL(productType, productId, 'paypal');

	if ( $('div#paypal-bt-container').html()=='' ){

		braintree.setup(BTclientToken, "paypal", {
			container : 'paypal-bt-container',
			displayName: 'Soccer Fours',
			singleUse:true,
			amount: orderTotal,
			currency: 'USD',
			onReady : function () {
				//console.log('PayPal button onReady!');
			},
			onPaymentMethodReceived : function (paymentMethodNonceObj) { 

				$('#braintree-paypal-loggedin').hide();
			
				$('div#pp_ajax-loader').removeClass('hidden');
		
				$.post( postURL,
					{ 
						'selectedMethod' : paymentMethodNonceObj.type, 
						'nonce'  : paymentMethodNonceObj.nonce,
						'_token' : laravelToken
					},
					function (res) {
						if(res.result_key=='OK') {
							$('.payment-btns').html( 
								$('<p class="alert alert-success">')
									.append('Paypal Payment Approved <br>')
									.append(  $('<a>').attr('href', APP_URL+'/account/home').html('Back to my account') )
							);
						}
					},
					'json'
				);
			
			}, 
			onError : function (errorObj) {
				console.log(errorObj);
			}
		});

	}



}


/**
 * Setup Prepaid Method 
 * @param  {[type]} reservationId [description]
 * @param  {[type]} laravelToken  [description]
 * @return {[type]}               [description]
 */
function prepaidMethodSetup(reservationId, laravelToken) {
	
	$('div#paypal-bt-container').addClass('hidden');
	$('div#creditcard-fields').addClass('hidden');
	$('div#pay-with-prepaid-action').removeClass('hidden');
	
}


