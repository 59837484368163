//alert('test');
$(document).ready(function(){
    var document_height=$(window).height();
    $('.drawer-menu').css('height', document_height+'px');
});

var hash = document.location.hash;
var prefix = "tab_";
if (hash) {
    $('.nav-tabs a[href="'+hash.replace(prefix,"")+'"]').tab('show');
}

// Change hash for page-reload
$('.nav-tabs a').on('shown.bs.tab', function (e) {
        if ( $.browser.webkit == false ) {
            window.location.hash = e.target.hash.replace("#", "#" + prefix);
        }   else {
             window.location.href = e.target.hash.replace("#", "#" + prefix);
             window.location.href = e.target.hash.replace("#", "#" + prefix);
        }
})

$('#mainSessionsTabs a').click(function (e) {
  e.preventDefault();
  $(this).tab('show');
    	$(document).scrollTop(0,0);
});


if ($('.home-tabs').length > 0){
    $('.mobile-nav > li a').click(function (e) {
        if ($(this).data('tab') == '#why4v4' 
        		|| $(this).data('tab') == '#news' 
        		|| $(this).data('tab') == '#about' 
        		|| $(this).data('tab') == '#terms-conditions' 
        		|| $(this).data('tab') == '#privacy-policy'
        ){
        
        } else {
	        e.preventDefault()
	        var tab_link=$(this).data('tab');
	        $('.drawer-menu').removeClass('menu-visible');
	        $(tab_link+'_tab').trigger('click');
	        if($(window).width()<992){
	        	$(document).scrollTop(0,0);
            }
        }
    });
}



$('.toggle-menu').click(function(){
    $('.drawer-menu').addClass('menu-visible');
});

$('.menu-close').click(function(e){
    e.preventDefault();
    $('.drawer-menu').removeClass('menu-visible');
});

function DropDown(el) {
				this.dd = el;
				this.initEvents();
			}
			DropDown.prototype = {
				initEvents : function() {
					var obj = this;

					obj.dd.on('click', function(event){
						$(this).toggleClass('active');
						event.stopPropagation();
					});

					obj.dd.find('li').on('click', function(event) {
						var locationId = $(this).attr('data-location');
						var token = $(this).parent().attr('data-token');
						$.ajax(
							APP_URL + '/ajax-sessions-location/' + locationId,
							{
								type : 'POST',
								headers: {'X-CSRF-Token' : token },
								success: function (response) { $('#sessionsByLocation').html(response);}
							}
						);
                        $('#dd').removeClass('active');
						event.stopPropagation();
					});
				}
			}

			$(function() {

				var dd = new DropDown( $('#dd') );

				$(document).click(function() {
					// all dropdowns
					$('.wrapper-dropdown-5').removeClass('active');
				});

			});

$('.plus-sessions').click(function(){
    var max_sessions = $('.sessions_qty').attr('max'); 
    var sessions_qty=$('.sessions_qty').val();
    sessions_qty = parseInt(sessions_qty);
    
        if (sessions_qty>max_sessions){
        alert('You can prepay up to ' + max_sessions + ' sessions');
        $('.sessions_qty').val(max_sessions);
    } else {
        $('.sessions_qty').val(sessions_qty+1);
    }
});

$('.minus-sessions').click(function(){
    var min_sessions = $('.sessions_qty').attr('min'); 
    var sessions_qty=$('.sessions_qty').val();
    sessions_qty = parseInt(sessions_qty);
    if (sessions_qty<min_sessions){
        alert('You can´t buy less than ' + min_sessions + ' sessions');
        $('.sessions_qty').val(min_sessions);
    } else {
        $('.sessions_qty').val(sessions_qty-1);
    }
});


$('.plus-spots-btn').click(function(){
    var spots_qty=$('.spots-number input').val();
    spots_qty = parseInt(spots_qty);
    if (spots_qty>8){
    alert('You can save up to 8 spots');
    $('.spots-number input').val(8);
    } else {
    $('.spots-number input').val(spots_qty+1);
    }
});

$('.minus-spots-btn').click(function(){
    var spots_qty=$('.spots-number input').val();
    spots_qty = parseInt(spots_qty);
    if (spots_qty < 1) {
        alert('Please enter a valid number');
        $('.spots-number input').val(1);        
    } else {
    $('.spots-number input').val(spots_qty-1);
    }
});



(function($) {

		  // Browser supports HTML5 multiple file?
		  var multipleSupport = typeof $('<input/>')[0].multiple !== 'undefined',
		      isIE = /msie/i.test( navigator.userAgent );

		  $.fn.customFile = function() {

		    return this.each(function() {

		      var $file = $(this).addClass('custom-file-upload-hidden'), // the original file input
		          $wrap = $('<div class="file-upload-wrapper">'),
		          $input = $('<span class="file-upload-input">upload your profile picture</span>"'),
		          // Button that will be used in non-IE browsers
		          $button = $('.file-upload-button'),
		          // Hack for IE
		          $label = $('<label class="file-upload-button" for="'+ $file[0].id +'">+</label>');

		      // Hide by shifting to the left so we
		      // can still trigger events
		      $file.css({
		        position: 'absolute',
		        left: '-9999px'
		      });

		      $wrap.insertAfter( $file )
		        .append( $file, $input);

		      // Prevent focus
		      $file.attr('tabIndex', -1);
		      $button.attr('tabIndex', -1);

		      $button.click(function () {
		        $file.focus().click(); // Open dialog
		      });

		      $file.change(function() {

		        var files = [], fileArr, filename;

		        // If multiple is supported then extract
		        // all filenames from the file array
		        if ( multipleSupport ) {
		          fileArr = $file[0].files;
		          for ( var i = 0, len = fileArr.length; i < len; i++ ) {
		            files.push( fileArr[i].name );
		          }
		          filename = files.join(', ');

		        // If not supported then just take the value
		        // and remove the path to just show the filename
		        } else {
		          filename = $file.val().split('\\').pop();
		        }

		        $input.text( 'Your avatar was updated' ) // Set the value
		          .attr('title', filename) // Show filename in title tootlip
		          .focus(); // Regain focus

		      });

		      $input.on({
		        blur: function() { $file.trigger('blur'); },
		        keydown: function( e ) {
		          if ( e.which === 13 ) { // Enter
		            if ( !isIE ) { $file.trigger('click'); }
		          } else if ( e.which === 8 || e.which === 46 ) { // Backspace & Del
		            // On some browsers the value is read-only
		            // with this trick we remove the old input and add
		            // a clean clone with all the original events attached
		            $file.replaceWith( $file = $file.clone( true ) );
		            $file.trigger('change');
		            $input.val('');
		          } else if ( e.which === 9 ){ // TAB
		            return;
		          } else { // All other keys
		            return false;
		          }
		        }
		      });

		    });

		  };

		  // Old browser fallback
		  if ( !multipleSupport ) {
		    $( document ).on('change', 'input.customfile', function() {

		      var $this = $(this),
		          // Create a unique ID so we
		          // can attach the label to the input
		          uniqId = 'customfile_'+ (new Date()).getTime(),
		          $wrap = $this.parent(),

		          // Filter empty input
		          $inputs = $wrap.siblings().find('.file-upload-input')
		            .filter(function(){ return !this.value }),

		          $file = $('<input type="file" id="'+ uniqId +'" name="'+ $this.attr('name') +'"/>');

		      // 1ms timeout so it runs after all other events
		      // that modify the value have triggered
		      setTimeout(function() {
		        // Add a new input
		        if ( $this.val() ) {
		          // Check for empty fields to prevent
		          // creating new inputs when changing files
		          if ( !$inputs.length ) {
		            $wrap.after( $file );
		            $file.customFile();
		          }
		        // Remove and reorganize inputs
		        } else {
		          $inputs.parent().remove();
		          // Move the input so it's always last on the list
		          $wrap.appendTo( $wrap.parent() );
		          $wrap.find('input').focus();
		        }
		      }, 1);

		    });
		  }

}(jQuery));

$('input[type=file]').customFile();

	function showImagePreview(rawField, imgTarget) 
    {
      if (rawField.files && rawField.files[0]) 
      {
        var filerdr = new FileReader();
        filerdr.onload = function(e) {
            $(imgTarget).attr('src', e.target.result);
        };
        filerdr.readAsDataURL(rawField.files[0]);
      }
    }	

	$('input.imgPreviewField').on('change', function(event){
		var rawField = $(this)[0],
			imgTarget = $(this).attr('data-img-target');
		showImagePreview( rawField, imgTarget ); 	
        
            if ($(this).hasClass('edit-input')){
              $('.preview-edit').hide();  
            } else {
        $('.file-upload-input').hide();
                $('.preview-container').show();
            }
        });



$('.remove-session, .cancel-session').click(function(){
	return confirm('Confirm cancelation?');
});

$('.confirm-remove-pic').click(function (e) {
	return confirm('Remove picture permanently?');
});




$('#announcementModal').modal('show')





